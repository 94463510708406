import "./App.css";
import React, { useState, useRef } from "react";
import Background from "./components/Background";
import styled from "styled-components";
import validator from "validator";
import emailjs from "@emailjs/browser";
import pennyPeeperLogo from "./assets/images/logo/penny_peepers-removebg.png";

import {
  FaChartLine,
  FaRegLightbulb,
  FaPiggyBank,
  FaRobot,
  FaTwitter,
  FaInstagram,
  FaReddit,
} from "react-icons/fa";

const Container = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 40px 20px;
  color: white;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 84px;
  text-transform: uppercase;
  color: white;
  text-shadow: -4px -4px 0 #000, 4px -4px 0 #000, -4px 4px 0 #000,
    4px 4px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
  letter-spacing: 4px;
  -webkit-text-stroke: 2px black;
  font-weight: bold;
  padding: 0;
  margin: 0;
  line-height: 0.9;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    font-size: 60px;
    letter-spacing: 2px;
  }
  @media (max-width: 480px) {
    font-size: 48px;
    letter-spacing: 1px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const Logo = styled.img`
  height: 240px;
  width: auto;
  margin-top: 10px;
  transform: translateY(4px);
  image-rendering: pixelated;
  image-rendering: crisp-edges;
  transition: transform 0.1s ease-out;

  &:hover {
    cursor: pointer; /* Optional: to indicate interaction */
  }
  @media (max-width: 768px) {
    height: 180px;
  }
  @media (max-width: 480px) {
    height: 140px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
    align-items: center;
  }
`;

const FeaturesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    margin: 40px auto;
    padding: 0 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    padding: 0 5px;
  }
`;

const FeatureCard = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s;
  max-width: 90%;
  margin: 0 auto;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    padding: 15px;
    margin-left: -10px; /* Shift slightly left on medium screens */
  }

  @media (max-width: 480px) {
    padding: 10px;
    max-width: 85%;
    margin-left: -5px; /* Shift more to the left on small screens */
  }

  @media (max-width: 320px) {
    padding: 8px;
    max-width: 80%;
    margin-left: -5px; /* Shift even further on very small screens */
  }
`;

const FeatureIcon = styled.div`
  font-size: 36px;
  margin-bottom: 12px;
  color: #ffd700;
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 8px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 22px;
  margin-bottom: 8px;
  color: white;
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 6px;
  }
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const FeatureDescription = styled.p`
  font-size: 14px;
  color: #e0e0e0;
  line-height: 1.3;
  @media (max-width: 768px) {
    font-size: 13px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const SurveySection = styled.div`
  text-align: center;
  margin: 40px 0;
  padding: 20px;
`;

const SurveyButton = styled.a`
  display: inline-block;
  background: #ffd700;
  color: #000;
  padding: 15px 30px;
  border-radius: 4px;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  border: 2px solid #000;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #ffe44d;
  }

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 16px;
  }
`;

const SurveyText = styled.p`
  color: white;
  font-size: 20px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 0 #000;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 15px;
  }
`;

const FAQSection = styled.div`
  margin: 40px auto;
  max-width: 800px;
  color: white;
`;

const WaitlistContainer = styled.div`
  margin: 60px 0;
  text-align: center;
  max-width: 600px;
  margin: 60px auto;

  @media (max-width: 768px) {
    max-width: 90%; /* Slightly reduce width on medium screens */
    margin: 40px auto;
    margin-left: -5px; /* Reduce top and bottom margin */
  }

  @media (max-width: 480px) {
    max-width: 100%; /* Full width on small screens */
    margin: 30px auto;
    /* Further reduce margin */
    margin-left: -5px;
  }

  @media (max-width: 320px) {
    padding: 8px;
    max-width: 80%;
    margin-left: -5px; /* Shift even further on very small screens */
  }
`;

const WaitlistInput = styled.input`
  padding: 15px;
  width: 300px;
  background: transparent;
  border: 2px solid #fff;
  color: white;
  font-family: "ThaleahFat", sans-serif;
  font-size: 16px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-family: "ThaleahFat", sans-serif;
  }

  @media (max-width: 768px) {
    width: 90%; /* Allow the input to adapt to a smaller screen */
    max-width: 280px; /* Limit maximum width on medium screens */
    padding: 12px;
    margin-left: -5px;
  }

  @media (max-width: 480px) {
    width: 100%; /* Full width on small screens */
    max-width: none; /* Remove max-width to allow full responsiveness */
    padding: 10px;
    margin-left: -5px;
  }

  @media (max-width: 320px) {
    padding: 8px;
    max-width: 80%;
    margin-left: -9px; /* Shift even further on very small screens */
  }
`;

const WaitlistButton = styled.button`
  padding: 15px 30px;
  background: #ffd700;
  border: 2px solid #000;
  color: #000;
  cursor: pointer;
  font-family: "ThaleahFat", sans-serif;
  font-size: 16px;

  &:hover {
    background: #ffe44d;
  }

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 15px;
    margin-left: -5px;
  }

  @media (max-width: 480px) {
    padding: 10px 20px; /* Slightly smaller padding on small screens */
    font-size: 14px; /* Reduce font size on very small screens */
    margin-left: -5px;
  }
  @media (max-width: 320px) {
    padding: 8px;
    max-width: 80%;
    margin-left: -7px; /* Shift even further on very small screens */
  }
`;

const NewsletterCheckbox = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 320px) {
    padding: 8px;
    max-width: 80%;
    margin-left: -5px; /* Shift even further on very small screens */
  }
`;


function App() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [answer, setAnswer] = useState("");
  const [isSubscribedToNewsletter, setIsSubscribedToNewsletter] =
    useState(true);
  const form = useRef();

  const handleNewsletterChange = (event) => {
    setIsSubscribedToNewsletter(event.target.checked);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const validateEmail = (emailValue) => {
    if (!validator.isEmail(emailValue)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handleSummaryClick = () => {
    // Delay the update to the answer by 2 seconds (2000ms)
    setTimeout(() => {
      setAnswer("NO! SIKE");
    }, 2000);
  };


  const sendEmail = (e) => {
    e.preventDefault();
    
    // Validate email before sending
    if (email.trim() === "" || emailError) {
        console.log("Email is invalid or empty");
        return; // Prevent sending if email is invalid
    }

    emailjs
      .send("service_avjibqp", "template_5motnxe", { 
          user_email: email, 
          is_subscribed: isSubscribedToNewsletter
      }, "b3v0C3Avgz9pbdSfO")
      .then(
        () => {
          console.log("SUCCESS!");
          setEmail(""); // Clear the input field after sending
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="App">
      <Background>
        <Container>
          <HeaderContainer>
            <TitleContainer>
              <Title>
                <div>PENNY</div>
                <div>PEEPER</div>
              </Title>
            </TitleContainer>
            <Logo src={pennyPeeperLogo} alt="Penny Peeper Logo" />
          </HeaderContainer>
          <SurveySection>
            <SurveyText>
              Help us build the perfect expense tracker for you!
            </SurveyText>
            <SurveyButton
              href="https://forms.gle/iecYYZK3Si48ydZK8"
              target="_blank"
              rel="noopener noreferrer"
            >
              🎯 Tell Us Your Pain Points
            </SurveyButton>
          </SurveySection>
          <FeaturesSection>
            <FeatureCard>
              <FeatureIcon>
                <FaChartLine />
              </FeatureIcon>
              <FeatureTitle>Expense Tracking</FeatureTitle>
              <FeatureDescription>
                Track every penny with our intuitive expense monitoring system
              </FeatureDescription>
            </FeatureCard>

            <FeatureCard>
              <FeatureIcon>
                <FaRegLightbulb />
              </FeatureIcon>
              <FeatureTitle>Insightful Analytics</FeatureTitle>
              <FeatureDescription>
                Visualize your spending patterns with detailed analytics and
                reports
              </FeatureDescription>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>
                <FaPiggyBank />
              </FeatureIcon>
              <FeatureTitle>Savings Goals</FeatureTitle>
              <FeatureDescription>
                Set and achieve your financial goals with smart milestone
                tracking
              </FeatureDescription>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>
                <FaRobot />
              </FeatureIcon>
              <FeatureTitle>AI Recommendations</FeatureTitle>
              <FeatureDescription>
                Get personalized savings tips powered by advanced AI algorithms
              </FeatureDescription>
            </FeatureCard>
          </FeaturesSection>
          <WaitlistContainer>
            <form ref={form} onSubmit={sendEmail}>
              <div
                style={{ display: "flex", justifyContent: "center", gap: "10px" }}
              >
                <WaitlistInput
                  type="email"
                  name="user_email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={handleEmailChange}
                />
                <WaitlistButton type="submit">Join Waitlist</WaitlistButton>
              </div>
              {emailError && email.trim() !== "" && (
                <div style={{ color: "red" }}>{emailError}</div>
              )}
            </form>
            <NewsletterCheckbox>
              <input
                type="checkbox"
                id="newsletter"
                checked={isSubscribedToNewsletter}
                onChange={handleNewsletterChange}
              />
              <label htmlFor="newsletter">
                Keep me updated with money-saving tips and product news
              </label>
            </NewsletterCheckbox>
          </WaitlistContainer>

          <FAQSection>
            <h2>Frequently Asked Questions</h2>
            <details>
              <summary onClick={handleSummaryClick}>
                Is my financial data secure?
              </summary>
              <p>{answer}</p>
            </details>
          </FAQSection>
          <div>
            <a
              href="https://x.com/Penny_Peeper?t=84Q3jQzKAanhStRiiPs3lg&s=08"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter size={30} color="white" />
            </a>

            <a
              href="https://www.instagram.com/pennypeepers_?igsh=ZXdwODQ0cXBuMnEz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={30} color="white" />
            </a>

            <a
              href="https://www.reddit.com/r/PennyPeeper/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaReddit size={30} color="white" />
            </a>
          </div>
        </Container>
      </Background>
    </div>
  );
}

export default App;
