import styled from "styled-components";

const Background = styled.div`
  min-height: 100;
  width: 100%;
  background: #1a1c2c;
  background-image: linear-gradient(
    0deg,
    rgba(26, 28, 44, 0.8) 0%,
    rgba(26, 28, 44, 0.4) 100%
  );
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60%;
    background: url("/path-to-your-silhouette.png") repeat-x bottom;
    background-size: auto 100%;
    z-index: 1;
  }
`;

export default Background;
